import React from "react"
import "./process-list.scss"

const ProcessList = () => {
  return (
    <div className="process-content">
      <ol className="process-list">
        <li className="process-list-item current">
          <button>Questionnaire</button>
        </li>
        <li className="process-list-item">
          <button>Initial Evaluation</button>
        </li>
        <li className="process-list-item">
          <button>Create Web Presence Plan</button>
        </li>
        <li className="process-list-item">
          <button>Create Site Map</button>
        </li>
        <li className="process-list-item">
          <button>Develop Branding</button>
        </li>
        <li className="process-list-item">
          <button>Create Style Guide</button>
        </li>
        <li className="process-list-item">
          <button>Create Wire Frames</button>
        </li>
        <li className="process-list-item">
          <button>Create Site Content</button>
        </li>
        <li className="process-list-item">
          <button>Build High Fidelity Mockups</button>
        </li>
        <li className="process-list-item">
          <button>Website Development</button>
        </li>
        <li className="process-list-item">
          <button>Pre-Launch Testing</button>
        </li>
        <li className="process-list-item">
          <button>Launch Website</button>
        </li>
        <li className="process-list-item">
          <button>Maintenance</button>
        </li>
        <li className="process-list-item">
          <button>Add New Pages and Features</button>
        </li>
      </ol>
      <ol className="process-detail-list">
        <li>
          <h3 className="process-detail-heading">Questionnaire</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Completed
              Questionnaire
            </p>
            <ul>
              <li>Complete an Online Questionnaire</li>
              <li>
                This questionnaire will help us to know how we can help you
                achieve your digital marketing goals. We will be able to design
                a personalized plan based on your goals and budget.
              </li>
              <li>
                Providing your budget at the start of this process will help us
                design a realistic plan.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Initial Evaluation</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Recommendations
              Document
            </p>
            <ul>
              <li>Evaluate existing properties</li>
              <li>Evaluate client goals</li>
              <li>Evaluate client's existing marketing processes</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Create Web Presence Plan</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Web Presence Plan
            </p>
            <ul>
              <li>Website</li>
              <li>Features</li>
              <li>Content Plan</li>
              <li>Technology/Platform</li>
              <li>Social Media Properties</li>
              <li>PPC Marketing</li>
              <li>SEO</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Create Site Map</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Site Map
            </p>
            <ul>
              <li>List of all of your site's pages and how they connect.</li>
              <li>List all external pages that connect to your website.</li>
              <li>Describe the interactions that take place on each page.</li>
              <li>
                Your site map is a living document. We will keep it updated for
                you.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Develop Branding</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Branding Elements
            </p>
            <ul>
              <li>Domain</li>
              <li>Logo</li>
              <li>Color Scheme</li>
              <li>Fonts</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Create Style Guide</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Storybook Style
              Guide
            </p>
            <p>
              Your style guide has more details about the look and feel of your
              site.
            </p>
            <ul>
              <li>Create Storybook Style Guide</li>
              <li>Branding</li>
              <li>Headings</li>
              <li>Buttons</li>
              <li>Links</li>
              <li>Images</li>
              <li>Animations</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Create Wireframes</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Wireframes
              Documents
            </p>
            <ul>
              <li>Create layouts for all pages in the sitemap</li>
              <li>
                Some pages may have similar templates. For example service pages
                and blog posts will most likely use the same template.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">
            Build High Fidelity Mockups
          </h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> High Fidelity
              Mockups
            </p>
            <ul>
              <li>Create detailed mockups of each page in the sitemap</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Website Development</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Website on Staging
              Domain
            </p>
            <ul>
              <li>Code website on staging domain.</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Pre-launch Q/A</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Website on Staging
              Domain
            </p>
            <ul>
              <li>Fix any bugs on the website and test.</li>
              <li>Security testing</li>
              <li>Test site interactions</li>
              <li>Test all forms</li>
              <li>Accessibility testing</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Launch Production Website</h3>
          <div className="process-detail-content">
            <p>
              <span className="elr-bold">Deliverable:</span> Live Website on
              Production Domain
            </p>
            <ul>
              <li>Launch your new website</li>
            </ul>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Website Maintenance</h3>
          <div className="process-detail-content">
            <p>
              After your website launches we monitor for any potential issues.
            </p>
          </div>
        </li>
        <li>
          <h3 className="process-detail-heading">Add New Pages and Features</h3>
          <div className="process-detail-content">
            <p>
              After your website launches you will need to add new content to
              keep the site fresh.
            </p>
            <ul>
              <li>New Pages</li>
              <li>Blog Posts</li>
            </ul>
          </div>
        </li>
      </ol>
    </div>
  )
}

export default ProcessList
