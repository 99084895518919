import React from "react"
import SeoHeaders from "../components/seo-headers/seo-headers"
import LayoutMain from "../components/layout-main/layout-main"
import HeroUnit from "../components/hero-unit/hero-unit"
import ProcessList from "../components/process-list/process-list"

const ProcessPage = ({
  isLoggedIn = process.env.GATSBY_ACTIVE_ENV === "development" ||
    process.env.GATSBY_ACTIVE_ENV === "staging",
}) => {
  return (
    <LayoutMain className="page page-process">
      <SeoHeaders title="Our Process" keywords={[`Process`]} />
      <HeroUnit pageTitle="Our Process" />
      <section>
        <div className="content-section">
          <div className="content-section-inner">
            <div className="page-content">
              <h2 className="main-heading">Web Presence Development Process</h2>
              {isLoggedIn ? <ProcessList /> : <p>Sign up to view process</p>}
            </div>
          </div>
        </div>
      </section>
    </LayoutMain>
  )
}

export default ProcessPage
